export const validateBranch = (data) => {
    console.log(data);
    return {
        type: "VALID_BRANCH",
        payload: data,
    };
};

export const detailCard = () => {
    console.log('Aqui');
    return {
        type: "DETAIL_CARD"
    };
};

export const activeCard = () => {
    return {
        type: "ACTIVE_CARD"
    };
};

export const activeLot = () => {
    return {
        type: "ACTIVE_LOT",
    };
};

export const setError = (data) => {
    console.log(data);
    return {
        type: "ERROR",
        payload: data,
    };
};