// import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateBranch } from "../../global/actions";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function Confirmation() {
  // const validateBranchReducer = useSelector((state) => state.validateBranchReducer);
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const loadDataOnlyOnce = () => {};

  // useEffect(() => {
  //     loadDataOnlyOnce();
  // }, []);

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        <h1 className="text-center font-bold text-2xl mb-4">Gracias</h1>
        <button
          className="btn-primary block md:mx-auto w-full max-w-lg md:w-1/2"
          onClick={() => {
            dispatch(validateBranch({}));
            navigate("/acciones");
          }}
        >
          Finalizar
        </button>
      </div>
    </main>
  );
}
