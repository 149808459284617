import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { detailCard, activeCard, activeLot } from "../../global/actions";
import img_temp from "../../assets/img/img_temp.jpg";

export default function Action() {
  const validateBranchReducer = useSelector(
    (state) => state.validateBranchReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const loadDataOnlyOnce = () => {};

  useEffect(() => {
    // if (validateBranchReducer)
    // console.log(validateBranchReducer.code === undefined)
    if (validateBranchReducer.code === undefined) navigate("/");
  }, []);

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="w-full md:w-1/2">
        <ul role="list" className="grid gap-4 grid-cols-1 lg:grid-cols-2">
          <li className="card divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 ">
                <h3 className="text-gray-900 text-sm font-medium">
                  Consultar Tarjeta
                </h3>
                <p className="mt-1 text-gray-500 text-sm">
                  Obtener informacion de la tarjeta.
                </p>
              </div>
              <img
                className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                src={img_temp}
                alt=""
              />
            </div>

            <button
              onClick={() => {
                dispatch(detailCard());
                navigate("/validar");
              }}
              className="block w-full text-center py-4 px-8 text-sm text-gray-700 font-bold rounded-b-lg hover:text-gray-500"
            >
              {/* <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
              Consultar
            </button>
          </li>

          <li className="card divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 ">
                <h3 className="text-gray-900 text-sm font-medium ">
                  Consultar Lote
                </h3>
                <p className="mt-1 text-gray-500 text-sm ">
                  Obtener informacion del lote.
                </p>
              </div>
              <img
                className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                src={img_temp}
                alt=""
              />
            </div>

            <button className="block w-full text-center py-4 px-8 text-sm text-gray-700 font-bold rounded-b-lg hover:text-gray-500">
              {/* <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
              Consultar
            </button>
          </li>

          <li className="card divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 ">
                <h3 className="text-gray-900 text-sm font-medium ">
                  Activar Tarjeta
                </h3>
                <p className="mt-1 text-gray-500 text-sm ">
                  Habilitar Tarjeta.
                </p>
              </div>
              <img
                className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                src={img_temp}
                alt=""
              />
            </div>

            <button
              onClick={() => {
                dispatch(activeCard());
                navigate("/validar");
              }}
              className="block w-full text-center py-4 px-8 text-sm text-gray-700 font-bold rounded-b-lg hover:text-gray-500"
            >
              {/* <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
              Activar
            </button>
          </li>

          <li className="card divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 ">
                <h3 className="text-gray-900 text-sm font-medium ">
                  Registrar Lote
                </h3>
                <p className="mt-1 text-gray-500 text-sm ">
                  Registrar lote en sistema.
                </p>
              </div>
              <img
                className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                src={img_temp}
                alt=""
              />
            </div>

            <button
              onClick={() => {
                dispatch(activeLot());
                navigate("/validar");
              }}
              className="block w-full text-center py-4 px-8 text-sm text-gray-700 font-bold rounded-b-lg hover:text-gray-500"
            >
              {/* <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
              Registrar
            </button>
          </li>
        </ul>
      </div>

      <Link to="/" className="link-primary mt-4">
        Volver
      </Link>
    </main>
  );
}
