import validateBranchReducer from "./branch";
import actionCardReducer from "./card";
import setError from "./error";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    validateBranchReducer: validateBranchReducer,
    actionCard: actionCardReducer,
    error: setError,
});

export default allReducers;
