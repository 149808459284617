import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CARD } from "../../data/card";
import { firebase } from "../../config/firebase";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// export const CardDetails = () => {
export default function ActiveCard() {
  const { id } = useParams();
  const validateBranchReducer = useSelector(
    (state) => state.validateBranchReducer
  );
  const [card, setCard] = useState(false);
  const [type, setType] = useState("");
  // const actionCard = 2
  const actionCard = useSelector((state) => state.actionCard);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [cedula, setCedula] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isGift, setIsGift] = useState(false);
  const [recipientName, setRecipientName] = useState("");
  const [recipientCedula, setRecipientCedula] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  let loading = true;
  const navigate = useNavigate();

  const loadDataOnlyOnce = () => {
    console.log(id);
    console.log(validateBranchReducer);
    console.log(actionCard);
    loading = false;
    let info = id.split("-");
    if (actionCard === 1) {
      setType("TARJETA");
      if (info.length !== 3) {
        navigate("/");
      } else {
        const activateCard = firebase.functions().httpsCallable("activateCard");

        activateCard({
          quantity: 1,
          client: info[0],
          idLote: info[1],
          cardId: info[2],
          branchId: validateBranchReducer.code,
          partnerId: validateBranchReducer.id_doc,
        })
          .then((res) => {
            console.log(res);
            setCard(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    } else if (actionCard === 2) {
      setType("LOTE");
      if (info.length !== 2) {
        navigate("/");
      } else {
        const distributeLot = firebase
          .functions()
          .httpsCallable("distributeLot");

        distributeLot({
          client: info[0],
          partner_id_i: validateBranchReducer.id,
          partner_id: validateBranchReducer.id_doc,
          branchId: validateBranchReducer.code,
          lot: info[1],
          quantity: 1,
        })
          .then((res) => {
            console.log(res);
            setCard(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  const confirmaActivation = () => {
    setIsLoading(true);
    let info = id.split("-");
    const updateBuyerCard = firebase
      .functions()
      .httpsCallable("updateBuyerCard");

    updateBuyerCard({
      client: info[0],
      idLote: info[1],
      code: info[2],
      email: email,
      isGift: isGift,
      recipientEmail: recipientEmail,
      recipientId: recipientCedula,
      recipientName: recipientName,
      id: cedula,
      name: name,
      phone: phone,
    })
      .then((res) => {
        console.log(res);
        navigate("/confirmar");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(validateBranchReducer.code);
    // if (validateBranchReducer.code === undefined) navigate("/");
    if (loading) {
      loading = false;
      setIsLoading(false);
      loadDataOnlyOnce();
    }
  }, []);

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        {/* {card? ( */}
        {isLoading ? (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        ) : (
          <>
            <h1 className="text-center font-bold text-2xl">ACTIVAR {type}</h1>

            {actionCard === 1 && (
              <>
                <p className="my-4">
                  Ingrese los datos del usuario que adquirió la tarjeta
                </p>

                <div className="grid gap-3 grid-cols-1">
                  <input
                    className="form-control"
                    placeholder="Nombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    className="form-control"
                    placeholder="Cédula"
                    value={cedula}
                    onChange={(e) => setCedula(e.target.value)}
                  />
                  <input
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    className="form-control"
                    placeholder="Teléfono"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />

                  <div className="flex items-center justify-center mb-3">
                    <p>¿Es para regalar?</p>
                    <input
                      style={{
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 8,
                        marginLeft: 10,
                        marginRight: 10,
                        marginBottom: 8,
                        display: "inline-block",
                        border: "1px solid #ccc",
                        borderRadius: 4,
                        boxSizing: "border-box",
                      }}
                      placeholder="¿Es para regalar?"
                      type="checkbox"
                      value={isGift}
                      onChange={(e) => {
                        if (isGift === false) setIsGift(true);
                        else setIsGift(false);
                      }}
                    />
                  </div>
                </div>

                {isGift && (
                  <div className="grid gap-3 grid-cols-1 mb-4">
                    <input
                      className="form-control"
                      placeholder="Nombre del receptor"
                      value={recipientName}
                      onChange={(e) => setRecipientName(e.target.value)}
                    />
                    <input
                      className="form-control"
                      placeholder="Cédula del receptor"
                      value={recipientCedula}
                      onChange={(e) => setRecipientCedula(e.target.value)}
                    />
                    <input
                      className="form-control"
                      placeholder="Email del receptor"
                      value={recipientEmail}
                      onChange={(e) => setRecipientEmail(e.target.value)}
                    />
                  </div>
                )}

                <button
                  className="btn-primary block md:mx-auto w-full max-w-lg md:w-1/2"
                  onClick={() => confirmaActivation()}
                >
                  Continuar
                </button>
              </>
            )}

            {actionCard === 2 && (
              <button
                className="btn-primary block md:mx-auto w-full max-w-lg md:w-1/2"
                onClick={() => navigate("/confirmar")}
              >
                Continuar
              </button>
            )}
          </>
        )}
        {/* ) : ( */}
        {/* <section className="flex flex-col items-center justify-center">
                        <h1 className="text-center font-bold text-2xl mb-4">ACTIVANDO {type}</h1>
                        <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </section> */}
        {/* )} */}
      </div>
    </main>
  );
}
