const CARD = {
    code: "",
    date: {
        activatedOn: null,
        expiresOn: null,
        issuedOn: null,
        validFrom: null,
        voidedOn: null,
    },
    ledger: [],
    metadata: {
        description: "",
        subtitle: "",
        term: "",
        title: "",
    },
    status: "",
    validity_time: "",
    value: {
        backing: null,
        equivalentValuePerUnit: null,
        initialUnits: null,
        initialValue: null,
        remainingUnits: null,
        remainingValue: null,
    },
};

export { CARD };