const validateBranchReducer = (state = {}, action) => {
    console.log(state);
    console.log(action);
    switch (action.type) {
        case "VALID_BRANCH":
            return action.payload;

        case "INVALID_BRANCH":
            return {};
        default:
            return state;
    }
};

export default validateBranchReducer;
