const actionCardReducer = (state = 0, action) => {
    console.log(state);
    console.log(action);
    switch (action.type) {
        case "DETAIL_CARD":
            return 0;
        case "ACTIVE_CARD":
            return 1;
        case "ACTIVE_LOT":
            return 2;
        default:
            return state;
    }
};

export default actionCardReducer;
