// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getFirestore } from "firebase/firestore/lite";
// Follow this pattern to import other Firebase services
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCp_HIuaKtM5prx8JHy8naRKrKgbMsbKcI",
    authDomain: "mintcard-system.firebaseapp.com",
    projectId: "mintcard-system",
    storageBucket: "mintcard-system.appspot.com",
    messagingSenderId: "1011097446077",
    appId: "1:1011097446077:web:f281d083b0d3449e2439d4",
    measurementId: "G-GW2Y1YE42W",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
// const db = getFirestore(app);
// const storage = getStorage(app, "gs://kocars-desarrollo.appspot.com");

export { firebase };
